<!--
File: MapSelectParams.vue
Description: Shows the attributes of the road
uses https://github.com/MisterTaki/vue-table-with-tree-grid
-->
<template>
  <div class="md-layout">
    <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="50"
      md-mode="indeterminate" />
    <div class="md-layout-item md-small-size-100 md-size-50">
      <VectorTypesDropdown :label="$t('map.vector_types')" v-model="selectedVectorType"
        :initial_value="selectedVectorType" @selectionChanged='onVectorTypeChange' />
      <!--RoadTypesDropdown :label="'Road type'" v-model="selectedRoadType" :disabled="selectedVectorType !== '!!!roads'"
        :initial_value="selectedRoadType" @selectionChanged='onRoadTypeChanged' /-->
      <!--YearsDropdown :label="$t('road_network.survey_year')" v-model="survey_year" :initial_value="survey_year" /-->
    </div>
    <div class="md-layout-item md-small-size-100 md-size-50">
      <ParamTypesDropdown v-if="selectedVectorType === 'roads'" :label="'Param type'" v-model="selectedParamType"
        :disabled="paramsDropdownDisabled" :initial_value="selectedParamType" @selectionChanged='onParamTypeChanged' />
      <!--StyleTypesDropdown :label="'Show as'" v-model="selectedStyleType" :disabled="selectedVectorType !== 'roads'"
        :initial_value="selectedStyleType" @selectionChanged='onStyleTypeChanged' /-->
      <!--md-checkbox :disabled="selectedVectorType !== 'roads'" v-model="showValues">Show values</md-checkbox-->
      <template v-if="selectedVectorType === 'roads'">
        <a href="#" @click.stop.prevent="onResetParams">Reset</a>
      </template>
    </div>

    <VueTabs>
      <v-tab title="View">
        <TreeGrid class="md-layout-item md-small-size-100 md-size-100 treegrid" :data="viewGridData" :stripe="true"
          :columns="viewGridColumns" :tree-type="true" :expand-type="false" :selection-type="false" :max-height=200>
          <template slot="value" slot-scope="scope">
            <template v-if="selectedVectorType === 'roads'">
              <a href="#" @click.stop.prevent="onViewGridClick(scope.row.name, scope.row.value, scope.row.label)">
                {{ scope.row.value }}
              </a>
            </template>
            <template v-else>
              {{ scope.row.value }}
            </template>
          </template>
        </TreeGrid>
      </v-tab>

      <v-tab title="List of roads" v-if="selectedVectorType === 'roads'">
        <TreeGrid class="md-layout-item md-small-size-100 md-size-100" :data="roadsListGridData" :stripe="true"
          :tree-type="true" :columns="roadsListGridColumns" :expand-type="false" :selection-type="false"
          :max-height=200>
          <template slot="value" slot-scope="scope">
            <a href="#" @click.stop.prevent="onViewGridClick(scope.row.param, scope.row.id, scope.row.label)">
              {{ scope.row.value }}
            </a>
          </template>
        </TreeGrid>
      </v-tab>
    </VueTabs>
  </div>
</template>

<script>
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import YearsDropdown from '../Dropdowns/YearsDropdown.vue'
import VectorTypesDropdown from './VectorTypesDropdown.vue'
import RoadTypesDropdown from './RoadTypesDropdown.vue'
import StyleTypesDropdown from './StyleTypesDropdown.vue'
import ParamTypesDropdown from './ParamTypesDropdown.vue'
import TreeGrid from 'vue-table-with-tree-grid'

export default {
  name: 'map-attribute-selector-component',
  data() {
    return {
      section_id: null,
      uuid: null,   // for bridge or tunnel
      showSpinner: false,
      viewGridData: [
        { name: "sect_info", label: this.$t('bridge.general_information'), children: [] },
        { name: "survey_data", label: this.$t('route.condition_data'), children: [] },
        //          { name: "indices", label: 'Indices and Maintenance', children: [] }
      ],
      viewGridColumns: [
        { label: 'Attribute', prop: 'label', width: '50%' },
        { label: this.$t('settings.value'), prop: 'value', type: 'template', template: 'value', width: '50%' },
      ],
      roadsListGridData: [],
      roadsListGridColumns: [
        { label: '', prop: '', width: '50px' },
        {
          label: `${this.$t('road_network.road_class')} / ${this.$t('road_network.road')} / ${this.$t('road_network.section')}`,
          prop: 'value', type: 'template', template: 'value', width: 'auto'
        },
        //{ label: 'param', prop: 'param', width: '0' },
      ],
      selectedRoadType: 'Paved',
      selectedStyleType: 'point',
      selectedVectorType: 'roads',
      selectedParamType: 'road_class',
      paramsDropdownDisabled: true,
      survey_year: null,
      showValues: false,
      tabName: 'home'
    }
  },
  components: {
    VueTabs,
    VTab,
    YearsDropdown,
    VectorTypesDropdown,
    RoadTypesDropdown,
    StyleTypesDropdown,
    ParamTypesDropdown,
    TreeGrid
  },
  created() {
    this.$eventHub.$on('mapItemSelected', this.onMapItemSelected);
  },
  beforeDestroy() {
    this.$eventHub.$off('mapItemSelected')
  },
  mounted() {
    this.$store.dispatch('LOAD_ROADS_GRID_INFO').then(() => {
      //      this.roadsListGridData[0].children = this.$store.state.ParamsComponent.roadsInfo
      this.roadsListGridData = this.$store.state.ParamsComponent.roadsInfo
    })
  },
  methods: {
    clearviewGridData() {
      this.viewGridData[0].children = []
      this.viewGridData[1].children = []
      this.paramsDropdownDisabled = true
    },
    reloadSectionInfo(section_id) {
      if (!section_id) return

      this.showSpinner = true
      this.$store.dispatch('LOAD_TREE_GRID_INFO', section_id).then(() => {
        this.mainInfo.forEach(el => { el.label = this.$t(el.label) })
        this.viewGridData[0].children = this.mainInfo
        //this.reloadSectionSurveyInfo() //this.years[0].id)
      })
      this.showSpinner = false
    },

    reloadSectionSurveyInfo(coords) {
      this.showSpinner = true
      this.$store.dispatch('FILL_TREE_GRID_SECTION_SURVEY_INFO', {
        year: this.survey_year, coords: coords
      }).then(() => {
        this.surveyInfo.forEach(el => { el.label = this.$t(el.label) })
        this.viewGridData[1].children = this.surveyInfo
      })
      this.showSpinner = false
    },

    reloadBridgeInfo(uuid) {
      if (!uuid) return

      this.showSpinner = true
      this.$store.dispatch('FILL_TREE_GRID_BRIDGE_INFO', uuid).then(() => {
        this.mainInfo.forEach(el => { el.label = this.$t(el.label) })
        this.surveyInfo.forEach(el => { el.label = this.$t(el.label) })
        this.viewGridData[0].children = this.mainInfo
        this.viewGridData[1].children = this.surveyInfo
      })
      this.showSpinner = false
    },

    reloadTunnelInfo(uuid) {
      if (!uuid) return

      this.showSpinner = true
      this.$store.dispatch('FILL_TREE_GRID_TUNNEL_INFO', uuid).then(() => {
        this.mainInfo.forEach(el => { el.label = this.$t(el.label) })
        this.surveyInfo.forEach(el => { el.label = this.$t(el.label) })
        this.viewGridData[0].children = this.mainInfo
        this.viewGridData[1].children = this.surveyInfo
      })
      this.showSpinner = false
    },

    onVectorTypeChange(value) {
      this.selectedVectorType = value
      this.$eventHub.$emit('vectorLayerChanged', value)
    },
    onViewGridClick(itemName, itemValue, itemDesc) {
      this.paramsDropdownDisabled = false
      this.$eventHub.$emit('viewGridClicked', { layer: 'vectorLayer', filter: itemName, value: itemValue, desc: itemDesc })
    },
    onResetParams() {
      this.selectedVectorType = 'roads'
      this.selectedParamType = 'road_class'
      this.clearviewGridData()
      this.$eventHub.$emit('resetStatus')
    },
    onRoadTypeChanged(value) {
      this.$eventHub.$emit('roadTypeChange', value)
    },
    onParamTypeChanged(value) {
      //this.$store.commit('SET_ROAD_PARAMETER', value)
      this.$eventHub.$emit('paramTypeChanged', { layer: 'vectorLayer', value: value })
    },
    onStyleTypeChanged(styletype) {
      this.$store.commit('SET_STYLE_TYPE', styletype)
    },

    onStrokeWidthChanged(stroke) {
      this.$store.commit('SET_STROKE_WIDTH', stroke)
    },

    onMapItemSelected({ layer, item_id, clicked_coords }) {    //feature_data) {
      this.clearviewGridData()
      if (!item_id) return

      switch (layer) {    //this.selectedVectorType) {
        case 'roads':
          this.uuid = null
          this.section_id = item_id
          this.reloadSectionInfo(item_id)
          this.reloadSectionSurveyInfo(clicked_coords)
          this.paramsDropdownDisabled = false
          break
        case 'bridges':
          this.uuid = item_id
          this.section_id = null
          this.reloadBridgeInfo(item_id)
          break
        case 'tunnels':
          this.uuid = item_id
          this.section_id = null
          this.reloadTunnelInfo(item_id)
          break
      }
    },
  },
  computed: {
    mainInfo() { return this.$store.state.ParamsComponent.mainInfo },
    surveyInfo() { return this.$store.state.ParamsComponent.surveyInfo },
    indexInfo() { return this.$store.state.ParamsComponent.indexInfo },
    years() { return this.$store.state.ParamsComponent.years },
  },
  watch: {
    survey_year(newValue, oldValue) {
      if (newValue !== oldValue && newValue) this.reloadSectionSurveyInfo(newValue)
    },
    selectedVectorType(newValue, oldValue) {
      this.clearviewGridData()
    },
    showValues(value) {
      //this.$eventHub.$emit('showValues', value)
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  padding: 20px 0;
}
.treegrid {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>