<!--
File: StyleTypesDropdown.vue
Description: show the dropdown combo with the style types llist (circe/line) for showing in the map.
-->
<template>
  <md-field>
    <label for="styleType">{{ label }}</label>
    <md-select v-model='styleType' name="styleType" id="styleType" :disabled="disabled" :required="is_required"
      @md-selected='onChange'>
      <md-option v-for="draw of styleTypes" :key="draw.key" :value="draw.key">
        {{ $t(draw.value) }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'style-types-dropdown',

    props: {
      label: { default: null, type: String },
      initial_value: { default: null, type: String },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        styleTypes: [{ key: 'line', value: "line" }, { key: 'point', value: "point" }],
        styleType: null,
        styleTypesListLoaded: false,
      }
    },

    mounted() {
      if (this.initial_value && !this.styleType) this.styleType = this.initial_value
      this.styleTypesListLoaded = true
    },

    methods: {
      onChange() {
        this.$emit('selectionChanged', this.styleType)
      },
    },

    watch: {
      initial_value(newValue, oldValue) {
        if (newValue !== oldValue && this.styleTypesListLoaded) {
          this.styleType = newValue
        }
      },
    }
  }
</script>