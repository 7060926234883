<!--
File: KG_Map.vue
Description: shows map with roads depending on the coordinates and zoom level provided.
-->
<template>
  <div class="maps-area">
    <div class="main-zone">
      <OlMap :style="{ height: '100%' }" :tileLayer="tileLayer" ref="mapInstance" 
        :mapHighlightedFeatureItem="mapHighlightedFeatureItem" :mapDefaultParams="mapDefaultParams"
        :filterOptions="filterQueryDef" :trackedEvents="['singleclick']"
        @change-map-state="onChangeMapState" @changeTileList="onChangeTileList"  
      />
      <!--div class='md-layout-item'>{{ $t('budget.selected') }} {{ $t(selection_info) }}
        <a v-if="(selection_url)" href="#" @click.stop.prevent="onClick()">{{ $t(selection_url.title) }}</a>
      </div-->
    </div>
  </div>
</template>
<script>
import OlMap from '@/components/OlMap'

export default {
  name: 'map-with-roads',
  props: {
    tileLayer: { default: 'osm', type: String },
    selectedStrokeWidth: { default: 1, type: Number },
  },
  data() {
    return {
      showSpinner: false,

      section_id: null, //{ default: null, type: Number },
      tileTypes: [],
      mapExtent: [],
      mapVectorSourceFeatures: [],
      mapHighlightedFeatureItem: null,
      pagestyle: '',
      filterQueryDef: {},

      mapDefaultParams: {
        dataProjection: 'EPSG:32643',     //EPSG:4326',
        map_centre: [494000, 4580000],    //[75.20484, 41.572522],
        max_zoom: 16,
        viewProjection: 'EPSG:4326',      //'EPSG:32643',
        zoom: 7,
        //min_zoom: 5
      },
      selection_url: {},
    }
  },
  components: {
    OlMap,
  },
  methods: {
    onMapVectorSourceIsLoading(state) {
      if (state) {
        this.mapVectorSourceFeatures = []
      } else {
        // if (this.selectedRoads.length > 0) {
        //   const chartData = this.$refs.mapInstance.getDataForChart('roadsLayer')
        //   this.mapVectorSourceFeatures = [...chartData]
        // }
      }
    },

    onChangeTileList(list) {
      // console.log("onChangeTileList: ", list)
      this.tileTypes = [...list]
    },

    onChangeMapState(payload) {
      //console.log("onChangeMapState: ", payload)
      //const map_state = btoa(JSON.stringify({ ...payload }))
    },

    onClick() {
      this.$router.push({ path: `${this.selection_url.href}` })
    }
  },
  computed: {
    selection_info() {
      return this.$store.state.MapComponent.selection_info
    },
  },
  watch: {
  }
}
</script>
<style lang="scss">
.maps-area {
  position: static;
  padding: 0 12px;
  left: 0;
  //height: calc(100% - 100px);
  width: 100%;
}

.main-zone {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.md-progress-spinner {
  margin: 24px;
  position: absolute;
  top: 25%;
  left: 55%;
  z-index: 10;
}
</style>