<!--
File: MapCharts.vue
Description: called from map main window, shows the chard with different parameters of the road
-->
<template>
  <div class="md-layout">
    <!--div class="md-layout-item md-small-size-100 md-size-50">
      <ParamTypesDropdown :label="'Param type'" v-model="selectedParamType" :initial_value="selectedParamType"
        @selectionChanged='onParameterChange' />
    </div-->
    <canvas class='md-layout-item' ref="myChart" id="MapChart"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js';
import ParamTypesDropdown from './ParamTypesDropdown.vue'

export default {
  name: 'map-charts-component',
  props: {
  },
  data() {
    return {
      section_id: null,
      chart: null,
      tableData: [],
      item_id: null,
      parameter: 'iri',
    }
  },

  components: {
    ParamTypesDropdown
  },
  created() {
    this.$eventHub.$on('mapItemSelected', this.onMapItemSelected)
    this.$eventHub.$on('viewGridClicked', this.onParameterChange)
    this.$eventHub.$on('paramTypeChanged', this.onParameterChange)
  },
  beforeDestroy() {
    this.$eventHub.$off('mapItemSelected')
    this.$eventHub.$off('viewGridClicked')
    this.$eventHub.$off('paramTypeChanged')
  },

  mounted() {
    this.reloadData()
  },

  methods: {
    reloadData() {
      if (['fclass', 'aadt', 'iri', 'rut_left', 'rut_right'].includes(this.parameter)) {
        const payload = {
          section_id: this.section_id,
          column_name: this.parameter
        }
        this.$store.dispatch('LOAD_CONDITION_DATA_FOR_CHART', payload).then((res) => {
          this.tableData = res
          //this.tableData.sort((a, b) => a.start_distance - b.start_distance)
          this.redrawChart()
        })
      }
    },

    async redrawChart() {
      var labels = []
      var data_series = []
      this.tableData.forEach((item) => {
        data_series.push(item[this.parameter])
        labels.push(item['start_distance'])
      })

      if (this.chart) this.chart.destroy(); // Уничтожаем предыдущий график перед созданием нового
      const ctx = document.getElementById('MapChart').getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            label: this.parameter,
            data: data_series,
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            borderColor: 'rgba(0, 255, 0, 1)',
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            xAxes: [{ ticks: { beginAtZero: true } }],
            yAxes: [{ ticks: { beginAtZero: true } }],
            plugins: {
              legend: { display: false, }
            }
          }
        }
      });
    },

    clearChartData() {
      this.tableData = []
      this.redrawChart()
    },
    onMapItemSelected({ layer, item_id, clicked_coords }) {
      console.log('onMapItemSelected (layer, section): ', layer, item_id)
      this.clearChartData()
      if (layer === 'roads' || layer === 'vectorLayer') {
          this.section_id = item_id
          this.reloadData()
      } else {
          this.section_id = null
      }
    },
    onParameterChange({ layer, parameter, value }) {
      this.parameter = parameter
      this.clearChartData()
      if (['iri', 'rut_left', 'rut_right'].includes(parameter)) {

        if (layer === 'roads' || layer === 'vectorLayer') this.reloadData()
      }
    }
  },

  computed: {
  },

  watch: {
  }
}
</script>
<style lang="scss" scoped></style>