<!--
File: RoadTypesDropdown.vue
Description: show the dropdown combo with the Road Types list (paved/unpaved) for showing in the map.
-->
<template>
  <md-field>
    <label for="roadType">{{ label }}</label>
    <md-select v-model='roadType' name="roadType" id="roadType" :disabled="disabled" :required="is_required"
      @md-selected='onChange'>
      <md-option v-for="road of roadTypes" :key="road" :value="road">
        {{ $t(road) }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
export default {
  name: 'road-types-dropdown',

  props: {
    label: { default: null, type: String },
    initial_value: { default: null, type: String },
    is_required: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean }
  },

  data() {
    return {
      roadTypes: ['Paved', 'Unpaved', 'All'],
      roadType: null,
      roadTypesListLoaded: false,
    }
  },

  mounted() {
    if (this.initial_value && !this.roadType) this.roadType = this.initial_value
    this.roadTypesListLoaded = true
  },

  methods: {
    onChange() {
      this.$emit('selectionChanged', this.roadType)
    },
  },

  watch: {
    initial_value(newValue, oldValue) {
      if (newValue !== oldValue && this.roadTypesListLoaded) {
        this.roadType = newValue
      }
    },
  }
}
</script>