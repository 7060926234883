<!--
File: VectorTypesDropdown.vue
Description: show the dropdown combo with the Vector Types list .
-->
<template>
  <md-field>
    <label for="vectorType">{{ label }}</label>
    <md-select v-model='vectorType' name="vectorType" id="vectorType" :disabled="disabled" :required="is_required"
      @md-selected='onChange'>
      <md-option v-for="vector of vectorTypes" :key="vector.key" :value="vector.key">
        {{ $t(vector.value) }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  import { getVectorTypes } from '../../store/maps/ranges_helper'

  export default {
    name: 'vector-types-dropdown',
    props: {
      label: { default: null, type: String },
      initial_value: { default: 'roads', type: String },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        vectorTypes: [],
        vectorType: null,
        vectorTypesListLoaded: false
      }
    },

    mounted() {
      this.vectorTypes = getVectorTypes(true)
      if (this.initial_value && !this.vectorType) this.vectorType = this.initial_value
      this.vectorTypesListLoaded = true
    },

    methods: {
      onChange() {
        this.$emit('selectionChanged', this.vectorType)   //, vector_desc)
      },
    },

    watch: {
      initial_value(newValue, oldValue) {
        if (newValue !== oldValue && this.vectorTypesListLoaded) {
          this.vectorType = newValue
        }
      },
    }
  }
</script>