<!--
File: ParamTypesDropdown.vue
Description: show the dropdown combo with the parmeter  list (iri/rutting/etc) for showing in the map.
-->
<template>
  <md-field>
    <label for="paramType">{{ label }}</label>
    <md-select v-model='paramType' name="paramType" id="paramType" :disabled="disabled" :required="is_required"
      @md-selected='onChange'>
      <md-option v-for="draw of paramTypes" :key="draw.key" :value="draw.key">
        {{ $t(draw.value) }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  import { getVectorTypes } from '../../store/maps/ranges_helper'

  export default {
    name: 'param-types-dropdown',

    props: {
      label: { default: null, type: String },
      initial_value: { default: null, type: String },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        paramTypes: [],
        paramType: null,
        paramTypesListLoaded: false,
      }
    },

    mounted() {
      this.paramTypes = getVectorTypes(false)
      if (this.initial_value && !this.paramType) this.paramType = this.initial_value
      this.paramTypesListLoaded = true
    },

    methods: {
      onChange() {
        this.$emit('selectionChanged', this.paramType)
      },
    },

    watch: {
      initial_value(newValue, oldValue) {
        if (newValue !== oldValue && this.paramTypesListLoaded) {
          this.paramType = newValue
        }
      },
    }
  }
</script>